import ContentCompareTable from '@pretto/bricks/website/shared/components/ContentCompareTable'

import PropTypes from 'prop-types'

import * as S from '../styles'

const CompareTable = ({ children, ...props }) => (
  <S.BlockLarge>
    <ContentCompareTable {...props}>{children}</ContentCompareTable>
  </S.BlockLarge>
)

CompareTable.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CompareTable
