import { csvToArray } from '@pretto/bricks/website/shared/components/ContentTable'
import CompareTable from '@pretto/bricks/website/simulators/components/CompareTable'
import { components } from '@pretto/bricks/website/utility/ContentBlock'

import Markdown from 'markdown-to-jsx'
import PropTypes from 'prop-types'
import React, { Fragment, memo } from 'react'

import * as S from './styles'

const cellOptions = {
  createElement(type, props, ...children) {
    if (
      [
        components.Emoji,
        components.Link,
        components.List,
        components.Text,
        'del',
        'em',
        'li',
        'strong',
        'sup',
      ].includes(type)
    ) {
      return React.createElement(type, props, ...children)
    }

    return React.createElement(Fragment, null, ...children)
  },
  forceBlock: true,
}

const ContentCompareTable = ({ children, columns, delimiter, title }) => {
  const rows = csvToArray(children, delimiter).reduce((previous, row) => {
    if (row.length <= 1) return previous

    return [
      ...previous,
      row.map((cell, index) => {
        if (index === 0) return <Markdown options={cellOptions}>{cell}</Markdown>
        return cell === 'true'
      }),
    ]
  }, [])

  const formattedColumns = columns.split(';')

  return (
    <S.Container itemScope itemType="http://schema.org/Table">
      <meta itemProp="about" content={title} />

      <CompareTable rows={rows} columns={formattedColumns} />
    </S.Container>
  )
}

ContentCompareTable.propTypes = {
  /** Content of the table. CSV like. */
  children: PropTypes.string.isRequired,
  /** Columns used to compare. */
  columns: PropTypes.number,
  /** Separation character to use for cell splitting. */
  delimiter: PropTypes.string,
  /** Title of the table used for schema */
  title: PropTypes.string.isRequired,
}

ContentCompareTable.defaultProps = {
  delimiter: ';',
}

export default memo(ContentCompareTable)
