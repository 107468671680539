import { breakpoints, g } from '@pretto/bricks/components/layout'

import { LogoPretto as LogoPrettoComponent } from '@pretto/zen/images/LogoPretto/LogoPretto'

import styled, { css } from 'styled-components'

const BORDER = `1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)}`
const RADIUS = g(0.5)

export const LogoPretto = styled(LogoPrettoComponent).attrs({ type: 'icon' })`
  height: ${g(5)};
`

export const RowCol = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-left: ${BORDER};
  padding-top: ${g(1.5)};

  &:last-child {
    border-left: 0;
  }
`
export const RowTitle = styled.div`
  padding: ${g(1.5)} ${g(2)};
  align-self: stretch;
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(1.5)} ${g(3)};
  }
`
export const RowItemContainer = styled.tr`
  display: block;

  &:first-child {
    ${RowTitle}, ${RowCol} {
      padding-top: ${g(2)};
      @media screen and (min-width: ${breakpoints.tablet}) {
        padding-top: ${g(3)};
      }
    }
  }

  &:last-child {
    ${RowTitle} {
      padding-bottom: ${g(2)};
      @media screen and (min-width: ${breakpoints.tablet}) {
        padding-bottom: ${g(3)};
      }
    }
  }
`
export const RowItem = styled.td`
  grid-column: 1 / -1;
`

export const Head = styled.thead`
  display: grid;
  grid-column: 2 / -1;
  border-left: ${BORDER};
  border-top: ${BORDER};
  border-top-left-radius: ${RADIUS};
  padding: ${g(2)} 0;
  @media screen and (min-width: ${breakpoints.tablet}) {
    border-right: ${BORDER};
    border-top-right-radius: ${RADIUS};
  }
`
export const Table = styled.table`
  &,
  ${RowItem} {
    display: grid;
    grid-template-columns: 45% repeat(${({ columns }) => columns}, 1fr);
    @media screen and (min-width: ${breakpoints.tablet}) {
      grid-template-columns: 55% repeat(${({ columns }) => columns}, 1fr);
    }
  }

  ${Head} {
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  }
`
export const HeadItem = styled.tr`
  align-self: center;
  justify-self: center;
  text-align: center;
  padding: 0 ${g(1)};

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`
export const Body = styled.tbody`
  grid-column: 1 / -1;
  border-top: ${BORDER};
  border-bottom: ${BORDER};

  @media screen and (min-width: ${breakpoints.tablet}) {
    border: ${BORDER};
    border-radius: ${RADIUS} 0 ${RADIUS} ${RADIUS};
  }
`
