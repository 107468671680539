import Badge from '@pretto/bricks/components/information/Badge'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const CompareTable = ({ columns, rows }) => (
  <S.Table columns={columns.length}>
    <S.Head>
      <S.HeadItem hide>
        <td></td>
      </S.HeadItem>
      {columns.map((col, i) => (
        <S.HeadItem key={i}>
          <td>{col === 'pretto' ? <S.LogoPretto /> : <Text size="x-small">{col}</Text>}</td>
        </S.HeadItem>
      ))}
    </S.Head>
    <S.Body>
      {rows.map(([title, ...columns], i) => (
        <S.RowItemContainer key={i}>
          <S.RowItem>
            <S.RowTitle>{title}</S.RowTitle>
            {columns.map((col, i) => {
              const badgeProps = col
                ? {
                    icon: 'check',
                    variant: 'success',
                  }
                : {
                    icon: 'cross',
                    variant: 'error',
                  }
              return (
                <S.RowCol key={i}>
                  <Badge {...badgeProps} />
                </S.RowCol>
              )
            })}
          </S.RowItem>
        </S.RowItemContainer>
      ))}
    </S.Body>
  </S.Table>
)

CompareTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.node).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.bool]))).isRequired,
}

export default memo(CompareTable)
