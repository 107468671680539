import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Container = styled.div`
  margin-left: ${g(-2)};
  margin-right: ${g(-2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
    margin-right: 0;
  }
`
